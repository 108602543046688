@import url('tailwindcss/base');
@import url('tailwindcss/components');
@import url('tailwindcss/utilities');

@font-face {
  font-family: 'VenusSB-MediumExtended';
  src: url('/wp-content/themes/helloworld/fonts/VenusSB-MediumExtended.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.scroll-snap-container {
  scroll-snap-type: y mandatory;
}

.scroll-snap-post {
  scroll-snap-align: start;
}

.wp-block-bookly-form {
  padding: 0 1rem;
}

.collapsible {
  transition: max-height 0.5s ease-out;
}

.collapsible.expanded {
  max-height: 50vh;
}

@media (min-width: 1024px) {
  .collapsible.expanded {
    max-height: 68px;
  }
}

.link {
  text-decoration-thickness: 8px;
  text-underline-offset: 8px;
}
